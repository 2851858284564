import { Component, OnInit } from '@angular/core';
import { NavigationService } from './shared/services';
import { environment } from '../environments/environment';

@Component({
  selector: 'web-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'angular-directory';
  loading = false;

  constructor(private navigationService: NavigationService) {

  }

  ngOnInit() {
    this.navigationService.loading$.subscribe((loading) => {
      this.loading = loading;
    });
  }
}
