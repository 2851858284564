
<div class="review-form">
    <!--<h3 *ngIf="isProviderTarget">Reviewing {{target.title}}</h3>-->
    <p class="comment-notes">To provide the best experience for future care seekers, the provider, office staff,
        and detailed review sections are required fields.<span class="required-indicator">*</span></p>
    <form [formGroup]="reviewFormGroup">
        <div class="row" formGroupName="surveyMeta" *ngIf="isProviderTarget">
            <div class="col-md-6 col-sm-12">
                <h4 class="header">How would you rate {{target.title}}?<span class="required-indicator">*</span>
                </h4>

                <div class="row ratings" formGroupName="providerScoreMeta">
                    <div class="col-md-6 col-sm-12 rating">
                        <span class="label">Knowledgeable</span> <web-star-rating
                            [formGroup]="providerScoreMetaGroup()" [formControlName]="'knowledgeRating'"
                            [readonly]="false" [max]="5" size="sm"></web-star-rating>
                    </div>
                    <div class="col-md-6 col-sm-12 rating">
                        <span class="label">Compassionate</span> <web-star-rating
                            [formGroup]="providerScoreMetaGroup()" [formControlName]="'compassionRating'"
                            [readonly]="false" [max]="5" size="sm"></web-star-rating>
                    </div>
                    <div class="col-md-6 col-sm-12 rating">
                        <span class="label">Active Listener</span> <web-star-rating
                            [formGroup]="providerScoreMetaGroup()" [formControlName]="'listenerRating'"
                            [readonly]="false" [max]="5" size="sm"></web-star-rating>
                    </div>
                    <div class="col-md-6 col-sm-12 rating">
                        <span class="label">Educates Patients</span> <web-star-rating
                            [formGroup]="providerScoreMetaGroup()" [formControlName]="'educationRating'"
                            [readonly]="false" [max]="5" size="sm"></web-star-rating>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <h4 class="header">How would you rate the Office Staff?<span class="required-indicator">*</span>
                </h4>

                <div class="row ratings" formGroupName="staffScoreMeta">
                    <div class="col-md-6 col-sm-12 rating">
                        <span class="label">Responsive</span> <web-star-rating
                            [formGroup]="staffScoreMetaGroup()" [formControlName]="'responsiveRating'"
                            [readonly]="false" [max]="5" size="sm"></web-star-rating>
                    </div>
                    <div class="col-md-6 col-sm-12 rating">
                        <span class="label">Timely Scheduling</span> <web-star-rating
                            [formGroup]="staffScoreMetaGroup()" [formControlName]="'benevolentRating'"
                            [readonly]="false" [max]="5" size="sm"></web-star-rating>
                    </div>
                    <div class="col-md-6 col-sm-12 rating">
                        <span class="label">Benevolent</span> <web-star-rating
                            [formGroup]="staffScoreMetaGroup()" [formControlName]="'timelySchedulingRating'"
                            [readonly]="false" [max]="5" size="sm"></web-star-rating>
                    </div>
                    <div class="col-md-6 col-sm-12 rating">
                        <span class="label">Short Wait Time</span> <web-star-rating
                            [formGroup]="staffScoreMetaGroup()" [formControlName]="'shortWaitRating'"
                            [readonly]="false" [max]="5" size="sm"></web-star-rating>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <h4 class="header">Review Title<span class="required-indicator">*</span></h4>
                    <input type="text" placeholder="Review Title" class="form-control"
                        formControlName="title" />

                    <div class="auto-moderator-feedback" *ngIf="reviewFormGroup.get('title')?.errors?.['minlength'] && reviewFormGroup.get('title')?.touched">
                        <i class='bx bxs-shield-minus negative'></i> Title must be at least 15 characters long.
                    </div>
                    <div class="auto-moderator-feedback" *ngIf="reviewFormGroup.get('title')?.errors?.['whitespace'] && reviewFormGroup.get('title')?.touched">
                        <i class='bx bxs-shield-minus negative'></i> Title required.
                    </div>
                </div>
                <div class="form-group">
                    <h4 class="header">Tell Us About Your Experience<span class="required-indicator">*</span></h4>
                    <web-slate-editor [isReadOnly]="false" [enableToolbar]="false"
                        (valueChanged)="onReviewContextChange($event)"></web-slate-editor>
                    <div class="auto-moderator-feedback mt-1">
                        <span *ngIf="isModerating" class="moderating-msg">Validating compliance...</span>
                        <span *ngIf="!isModerating && lastAutoTxtModeratorResult && autoTxtStatus">
                            <i *ngIf="autoTxtStatus.isCompliant" class='bx bxs-check-shield positive'></i>
                            <i *ngIf="!autoTxtStatus.isCompliant" class='bx bxs-shield-minus negative' ></i>
                            {{autoTxtStatus.msg}}
                        </span>
                    </div>
                </div>
                <div class="form-group" formGroupName="surveyMeta">
                    <div class="row"  formGroupName="visitMeta">
                        
                        <div class="col-lg-12 col-md-12 col-sm-12  mt-3">
                            <h4 class="header">Insurance</h4>
                            <ng-select [items]="insuranceResult?.docs" bindLabel="name" bindValue="id" groupBy="group"  formControlName="insuranceCompanyId"> </ng-select>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12  mt-3">
                            <h4 class="header">Location</h4>
                            <select formControlName="address_id">
                                <option *ngFor="let location of locationOptions;" [value]="location.address_id">{{location.label}}</option>
                            </select>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 mt-3">
                            <h4 class="header">Virtual Visit</h4>
                            <select formControlName="isVirtual">
                                <option [ngValue]="true">Yes</option>
                                <option [ngValue]="false">No</option>
                            </select>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 mt-3">
                            <h4 class="header">New Patient</h4>
                            <select formControlName="isNewPatient">
                                <option [ngValue]="true">Yes</option>
                                <option [ngValue]="false">No</option>
                            </select>
                        </div>
                    </div>
                    
                    
                </div>
            </div>
            <div class="col-md-6 col-sm-12" formGroupName="surveyMeta">
                <div class="form-group">
                    <h4 class="header">Additional Feedback</h4>
                </div>
                <div class="form-group">
                    <div class="row opt-feedback">
                        <div *ngFor="let question of defaultOptionBinaryFeedback; let i = index"
                            class="col-lg-6 col-md-12 col-sm-12 item">
                            <span class="label">{{question.label}}</span>
                            <span class="value"><web-tri-state-toggle [id]="question.id"
                                    (selectionChanged)="onOptBinaryFeedbackChange($event)"></web-tri-state-toggle></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--<div class="row">
            <div class="col-lg-12 col-md-12 form-group">
                <p class="comment-form-cookies-consent">
                    <input type="checkbox" formControlName="ack" id="ack">
                    <label for="ack">Disclaimer, attestation, etc.</label>
                </p>
            </div>
            <div class="col-lg-12 col-md-12">
                <button [disabled]="reviewFormGroup.invalid" type="submit"
                    (click)="submitReview()">Submit</button>
            </div>
        </div>-->
    </form>
</div>
