import { Component, Input, OnChanges, SimpleChanges, TemplateRef, inject } from '@angular/core';
import {  IReview, IReviewSearchResult } from '../../models/review.model';
import { IProvider } from '../../models/provider.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'web-review-list',
  templateUrl: './review-list.component.html',
  styleUrls: ['./review-list.component.scss'],
})
export class ReviewListComponent implements OnChanges {

  @Input() reviewsSearchResult: IReviewSearchResult;
  @Input() provider: IProvider;
  @Input() target: IProvider | IReview;

  isProvider = false;
  isReview = false;
  targetCollection: 'providers' | 'reviews' | 'posts';



  ngOnChanges(changes: SimpleChanges): void {
    if(changes['target']?.currentValue) {
      // @ts-ignore
      this.isProvider = this.target?.cmsMeta ? true : false;

      // @ts-ignore
      this.isReview = this.target?.replyPost ? true : false;

      if(this.isProvider) this.targetCollection = 'providers';
      if(this.isReview) this.targetCollection = 'reviews';
    }
  }

  
}
