import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { join } from 'lodash';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, map, of, throwError } from 'rxjs';
import { IProvider, IProviderSearchCriteria, IProviderSearchResult, IProviderTokenSuggestionResult, IProviderSearchNpiUnclaimedCriteria } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  private collection: string = 'providers';
  private baseUrl: string = join([environment.api.url, 'api', this.collection], '/');
  public profileClaimData: IProviderSearchNpiUnclaimedCriteria = {
    npi: '0'
  }

  constructor(private http: HttpClient) { 
    
  }

  search = (criteria: IProviderSearchCriteria): Observable<IProviderSearchResult> => {
    let url = `${this.baseUrl}/search?`;

    if (criteria.providerToken) {
      url += `pToken=${criteria.providerToken}&`;
    }

    if (criteria.locationToken) {
      url += `lToken=${criteria.locationToken}&`;
    }

    if (criteria.radius) {
      url += `radius=${criteria.radius}&`;
    }

    if (criteria.sort) {
      url += `sortBy=${criteria.sort}&`;
    }

    if (criteria.page) {
      url += `page=${criteria.page}&`;
    }

    if (criteria.pageSize) {
      url += `pageSize=${criteria.pageSize}&`;
    }

    if (criteria.limit) {
      url += `pageSize=${criteria.limit}&`;
    }

    return this.http
      .post<any>(url, {})
      .pipe(
          map(res => res.payload),
          catchError((err: HttpErrorResponse) => throwError(err)));
  };

  find = (id: string, depth: number = 1): Observable<IProvider> => {

    const url = `${this.baseUrl}/${id}?depth=${depth}&draft=false`;

    return this.http
      .get<any>(url)
      .pipe(
          map(res => res.payload),
          catchError((err: HttpErrorResponse) => throwError(err)));
  };

  tokenSuggestions = (criteria: IProviderSearchCriteria): Observable<IProviderTokenSuggestionResult> => {
    let url = `${this.baseUrl}/token/suggestion?`;

    if (criteria.providerToken) {
      url += `pToken=${criteria.providerToken}&`;
    }

    if (criteria.locationToken) {
      url += `lToken=${criteria.locationToken}&`;
    }

    if (criteria.radius) {
      url += `radius=${criteria.radius}&`;
    }

    if (criteria.sort) {
      url += `sortBy=${criteria.sort}&`;
    }

    if (criteria.page) {
      url += `page=${criteria.page}&`;
    }

    if (criteria.pageSize) {
      url += `pageSize=${criteria.pageSize}&`;
    }

    if (criteria.limit) {
      url += `pageSize=${criteria.limit}&`;
    }

    return this.http
      .post<any>(url, {})
      .pipe(
          map(res => res.payload),
          catchError((err: HttpErrorResponse) => throwError(err)));
  };

  geoPing = (providerId: string) => {
    const url = `${this.baseUrl}/${providerId}/g/ping`;
    return this.http
      .post<any>(url, {});
  }

  searchNpiUnclaimed = (criteria: IProviderSearchNpiUnclaimedCriteria): Observable<IProvider | null> => {
    let url = `${this.baseUrl}/search/npi/${criteria.npi}/unclaimed?`;

    if (criteria.licenseNumber) {
      url += `licenseNumber=${criteria.licenseNumber}&`;
    }

    return this.http
      .get<any>(url, {})
      .pipe(
          map(res => res.payload),
          catchError((err: HttpErrorResponse) => of(null)));
  };
}
