import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { join } from 'lodash';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { IPerspectiveResponse } from '../models';
import { Observable, catchError, map, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModeratorService {
  private service: string = 'moderator';
  private baseUrl: string = join([environment.api.url, 'api', this.service], '/');

  constructor(private http: HttpClient) { }

  analyze = (content: string): Observable<IPerspectiveResponse> => {
    const url = `${this.baseUrl}/analyze?`;

    const data = {
      content
    };

    return this.http
      .post<any>(url, data)
      .pipe(
        map(res => res.payload),
        catchError((err: HttpErrorResponse) => throwError(err)));
  }
}
