import { Component, HostListener, Input } from '@angular/core';
import { IUser } from '@sw-cms/shared-types';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'web-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    @Input() user: IUser | null | undefined = null;
    @Input() isLoggedIn: boolean = false

    constructor() { }
    
    forum = {
        link: environment.discourse.url,
        label: environment.discourse.label,
        icon: environment.discourse.icon
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;

        if (this.classApplied)
            this.isSticky = true;
    }

    // Navbar Sticky
    isSticky: boolean = false;
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 110) {
            this.isSticky = true;
        } else {
            this.isSticky = this.classApplied;
        }
    }

    onScrollTop() {
        const el = document.getElementsByTagName('html')[0];

        if(el)
            el.scrollIntoView({behavior: 'smooth'});
    }

    toggleMobile() {
        const body = document.body;
        const mobileTogleClass = 'mobile-menu-visible';

        if(body.classList.contains(mobileTogleClass))
            body.classList.remove(mobileTogleClass);
        else
            body.classList.add(mobileTogleClass);
    }

    // Signin/Signup Popup
    isSigninSignupOpen = false;
    openSigninSignupPopup(): void {
        this.isSigninSignupOpen = true;
    }

    closeSigninSignupPopup(): void {
        this.isSigninSignupOpen = false;
    }

    // Signin/Signup Tabs
    currentTab = 'tab1';
    switchTab(event: MouseEvent, tab: string) {
        event.preventDefault();
        this.currentTab = tab;
    }

}
