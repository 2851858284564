// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { EnvironmentType } from './EnvironmentType';

export const environment: EnvironmentType = {
  production: false,
  app: { name: 'sw-cms', stage: 'dev', auth: 'discourse' },
  company: {
    name: "Social Wellbeing, LLC"
  },
  api: { 
    url: 'https://dev-cms-api.socialwellbeing.io',
    endpoints: {
      cms: 'admin',
      sso: {
        login: 'auth/sso/init',
        logout:'auth/sso/logout'
      }
    } 
  },
  payload: { users_slug: 'users' },
  web: {
    url: 'https://dev.socialwellbeing.io/',
    endpoints: {
      sso: {
        login: 'auth/sso',
        logout: 'auth/sso/logout'
      }
    }
  },
  google: {
    maps: {
      token: 'AIzaSyAlsLRLlQaJUb1hTaayo4zThNVqykOhXZA'
    }
  },
  discourse: {
    url: 'https://discourse-dev.socialwellbeing.io',
    label: 'Sign-In / Join',
    authLabel: 'Chime In',
    icon: 'icon-Arrow-Right'
  },
  umami: {
    url: 'https://umami.socialwellbeing.io/script.js',
    websiteId: '05804854-3d56-48eb-93c0-a45d86bc8615'
  },
  stripe: {
      pricingTables: [
        {
          id: 'prctbl_1PPyzp2MLsSi7goNK9vNBQTx',
          key: 'pk_test_51PPql02MLsSi7goNqzS9OdvSIbvk1Vm1bYb3ojSn4kvurbWwZX617wAUilElRSpeIhH13mPzcyqDGH3fJQQ708L900Wdco7PlM',
          group: 'test'
        }
      ],
      identity: {
        flow: {
          url: 'https://dev-cms-api.socialwellbeing.io/api/webhook/stripe/identity/verification/session/create'
        }
      }
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
