import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, Renderer2 } from '@angular/core';

@Component({
  selector: 'web-slate-editor-toolbar-item',
    template: '<ng-content></ng-content>',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        style: 'cursor: pointer'
    },
})
export class SlateEditorToolbarItemComponent implements OnChanges {
  @Input() active = false;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onMouseDown: EventEmitter<MouseEvent> = new EventEmitter();

  constructor(private elementRef: ElementRef, private renderer2: Renderer2) {}

  @HostListener('mousedown', ['$event'])
  mousedown(event: MouseEvent) {
      event.preventDefault();
      console.log(event);
      this.onMouseDown.emit(event);
  }

  ngOnChanges() {
      this.renderer2.setStyle(this.elementRef.nativeElement, 'color', this.active ? 'black' : '#ccc');
  }
}
