import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'web-care-community-redirect',
  templateUrl: './care-community-redirect.component.html',
  styleUrls: ['./care-community-redirect.component.scss'],
})
export class CareCommunityRedirectComponent implements OnInit {
  ngOnInit(): void {
    window.location.href = environment.discourse.url;
  }
}
