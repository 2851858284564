
<!-- main header -->
<header class="main-header style-two" [class.fixed-header]="isSticky">

    <!-- header-lower -->
    <div class="header-lower">
        <div class="auto-container">
            <div class="outer-box">
                <div class="logo-box">
                    <figure class="logo"><a href="index.html"><img src="assets/images/logo-3.png" alt=""></a></figure>
                </div>
                <div class="menu-area">
                    <!--Mobile Navigation Toggler-->
                    <div class="mobile-nav-toggler" (click)="toggleMobile()">
                        <i class="icon-bar"></i>
                        <i class="icon-bar"></i>
                        <i class="icon-bar"></i>
                    </div>
                    <nav class="main-menu navbar-expand-md navbar-light">
                        <web-header-links [user]="user" [isLoggedIn]="isLoggedIn"></web-header-links>
                    </nav>
                </div>
                <!--<div *ngIf="!isLoggedIn" class="btn-box">
                    <a [routerLink]="['/auth/login']" class="theme-btn-one">{{forum.label}}<i [class]="forum.icon"></i></a>
                </div>
                
                <div *ngIf="isLoggedIn"  class="btn-box">
                    <div class="author-box">
                    <div class="author">
                        <div class="select-box">
                            <select class="wide">
                               <option data-display="Dr. Rex Allen">Dr. Rex Allen</option>
                               <option value="1">Cardiology</option>
                               <option value="2">Nurology</option>
                               <option value="4">Pragnency</option>
                            </select>
                        </div>
                    </div>
                </div>
                </div>-->
                
                <web-header-avatar style="display: contents;" [user]="user" [isLoggedIn]="isLoggedIn"></web-header-avatar>
            </div>
        </div>
    </div>

    <!--sticky Header-->
    <div class="sticky-header">
        <div class="auto-container">
            <div class="outer-box">
                <div class="logo-box">
                    <figure class="logo"><a href="index.html"><img src="assets/images/small-logo.png" alt=""></a>
                    </figure>
                </div>
                <div class="menu-area">
                    <nav class="main-menu clearfix">
                        <web-header-links [user]="user" [isLoggedIn]="isLoggedIn"></web-header-links>
                    </nav>
                </div>
                <web-header-avatar  style="display: contents;"  [user]="user" [isLoggedIn]="isLoggedIn"></web-header-avatar>
            </div>
        </div>
    </div>
</header>
<!-- main-header end -->

<!-- Mobile Menu  -->
<div class="mobile-menu">
    <div class="menu-backdrop" (click)="toggleMobile()"></div>
    <div class="close-btn" (click)="toggleMobile()"><i class="fas fa-times"></i></div>
    
    <nav class="menu-box">
        <div class="nav-logo"><a href="index.html"><img src="assets/images/logo-2.png" alt="" title=""></a></div>
        <div class="menu-outer">
            <web-header-links></web-header-links>
        </div>
        <div class="contact-info">
            <h4>Contact Info</h4>
            <ul>
                <li>ADDRESS</li>
                <li><a href="tel:TBD">TBD</a></li>
                <li><a href="mailto:info@socialwellbeing.io">info@socialwellbeing.io</a></li>
            </ul>
        </div>
        <div class="social-links">
            <ul class="clearfix">
                <li><a href="index.html"><span class="fab fa-twitter"></span></a></li>
                <li><a href="index.html"><span class="fab fa-facebook-square"></span></a></li>
                <li><a href="index.html"><span class="fab fa-pinterest-p"></span></a></li>
                <li><a href="index.html"><span class="fab fa-instagram"></span></a></li>
                <li><a href="index.html"><span class="fab fa-youtube"></span></a></li>
            </ul>
        </div>
    </nav>
</div><!-- End Mobile Menu -->

<button class="scroll-top scroll-to-target open" (click)="onScrollTop()" [class.open]="isSticky" data-target="html">
    <span class="fa fa-arrow-up"></span>
</button>