<!-- TODO: Make configurable-->
<div class="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group">
    <input type="radio" class="btn-check"  [name]="id" [id]="id + '_btnradio1'" autocomplete="off" (click)="onSelection('N')">
    <label class="btn btn-outline-primary" [for]="id + '_btnradio1'"  [style.background-color]="currentValue === 'N' ? 'red' : 'white'"><i class=" bx bx-x"></i></label>

    <input type="radio" class="btn-check"  [name]="id"  [id]="id + '_btnradio2'" autocomplete="off"  (click)="onSelection('NA')" checked>
    <label class="btn btn-outline-primary" [for]="id + '_btnradio2'"  [style.background-color]="currentValue === 'NA' || currentValue === null ? 'lightgray' : 'white'">---</label>

    <input type="radio" class="btn-check"  [name]="id" [id]="id + '_btnradio3'" autocomplete="off"  (click)="onSelection('Y')">
    <label class="btn btn-outline-primary" [for]="id + '_btnradio3'" [style.background-color]="currentValue === 'Y' ? 'green' : 'white'"><i class="bx bx-check"></i></label>
</div>
{{options?.N_NA_Y[0].value}}
