import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { withAngular } from 'slate-angular';
import { withHistory } from 'slate-history';
import { createEditor, Editor, Element, Transforms } from 'slate';
import { SlateTextMarkComponent, MarkTypes } from '../slate-text-mark/slate-text-mark.component';
import isHotkey from 'is-hotkey';

@Component({
  selector: 'web-slate-editor',
  templateUrl: './slate-editor.component.html',
  styleUrls: ['./slate-editor.component.scss'],
})
export class SlateEditorComponent implements OnInit, OnChanges {
  @Input() content : any = [{ children: [{ text: '' }] }];
  @Input() isReadOnly : boolean = true;
  @Input() enableToolbar : boolean = true;
  @Output() valueChanged = new EventEmitter<Element[]>();
  
  title = 'slate-angular-basic';
  value = [{ children: [{ text: '' }] }];

  @ViewChild('heading_1', { read: TemplateRef, static: true })
  headingOneTemplate!: TemplateRef<any>;

  @ViewChild('heading_2', { read: TemplateRef, static: true })
  headingTwoTemplate!: TemplateRef<any>;

  @ViewChild('heading_3', { read: TemplateRef, static: true })
  headingThreeTemplate!: TemplateRef<any>;

  @ViewChild('blockquote', { read: TemplateRef, static: true })
  blockquoteTemplate!: TemplateRef<any>;

  @ViewChild('ul', { read: TemplateRef, static: true })
  ulTemplate!: TemplateRef<any>;

  @ViewChild('ol', { read: TemplateRef, static: true })
  olTemplate!: TemplateRef<any>;

  @ViewChild('li', { read: TemplateRef, static: true })
  liTemplate!: TemplateRef<any>;

  editor = withHistory(withAngular(createEditor()));

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void  {
    if(changes['content']?.currentValue) {
      const contentCurrentValue = changes['content']?.currentValue;
      if(!Array.isArray(contentCurrentValue)) {
        this.value = [{ children: [{ text: contentCurrentValue }] }];
      }
      else {
        this.value = contentCurrentValue;
      }
    }
  }

  valueChange(value: Element[]) {
    this.valueChanged.emit(value);
  }

  renderElement = (element: any) => {
    if (element.type === 'heading-one') {
      return this.headingOneTemplate;
    }
    if (element.type === 'heading-two') {
      return this.headingTwoTemplate;
    }
    if (element.type === 'heading-three') {
      return this.headingThreeTemplate;
    }
    if (element.type === 'block-quote') {
      return this.blockquoteTemplate;
    }
    if (element.type === 'numbered-list') {
      return this.olTemplate;
    }
    if (element.type === 'bulleted-list') {
      return this.ulTemplate;
    }
    if (element.type === 'list-item') {
      return this.liTemplate;
    }
    return null;
  };

  renderText = (text: any) => {
    if (
      text[MarkTypes.bold] ||
      text[MarkTypes.italic] ||
      text[MarkTypes.code] ||
      text[MarkTypes.underline]
    ) {
      return SlateTextMarkComponent;
    }
    return null;
  };

  toggleBlock = format => {
    console.log(format);
    const isActive = this.isBlockActive(format);
    const isList = LIST_TYPES.includes(format);


    Transforms.unwrapNodes(this.editor, {
        //@ts-ignore
        match: n => LIST_TYPES.includes(Element.isElement(n) && n.type),
        split: true
    });

    const newProperties: Partial<Element> = {
        //@ts-ignore 
        type: isActive ? 'paragraph' : isList ? 'list-item' : format
    };
    Transforms.setNodes(this.editor, newProperties);

    if (!isActive && isList) {
        const block = { type: format, children: [] };
        Transforms.wrapNodes(this.editor, block);
    }
};

toggleMark = format => {
    const isActive = this.isMarkActive(format);

    if (isActive) {
        Editor.removeMark(this.editor, format);
    } else {
        Editor.addMark(this.editor, format, true);
    }
};

isBlockActive = format => {
    const [match] = Editor.nodes(this.editor, {
        //@ts-ignore 
        match: n => !Editor.isEditor(n) && Element.isElement(n) && n.type === format
    });

    return !!match;
};

isMarkActive = format => {
    const marks = Editor.marks(this.editor);
    return marks ? marks[format] === true : false;
};

keydown = (event: KeyboardEvent) => {
  if (isHotkey('shift+enter', event)) {
      event.preventDefault();
      this.editor.insertText('\n');
  }
  for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event as any)) {
          event.preventDefault();
          const mark = HOTKEYS[hotkey];
          this.toggleMark(mark);
      }
  }
};

toolbarItems = [
    {
        format: MarkTypes.bold,
        icon: 'format_bold',
        active: this.isMarkActive,
        action: this.toggleMark
    },
    {
        format: MarkTypes.italic,
        icon: 'format_italic',
        active: this.isMarkActive,
        action: this.toggleMark
    },
    {
        format: MarkTypes.underline,
        icon: 'format_underlined',
        active: this.isMarkActive,
        action: this.toggleMark
    },
    {
        format: MarkTypes.code,
        icon: 'code',
        active: this.isMarkActive,
        action: this.toggleMark
    },
    {
        format: 'heading-one',
        icon: 'looks_one',
        active: this.isBlockActive,
        action: this.toggleBlock
    },
    {
        format: 'heading-two',
        icon: 'looks_two',
        active: this.isBlockActive,
        action: this.toggleBlock
    },
    {
        format: 'block-quote',
        icon: 'format_quote',
        active: this.isBlockActive,
        action: this.toggleBlock
    },
    {
        format: 'numbered-list',
        icon: 'format_list_numbered',
        active: this.isBlockActive,
        action: this.toggleBlock
    },
    {
        format: 'bulleted-list',
        icon: 'format_list_bulleted',
        active: this.isBlockActive,
        action: this.toggleBlock
    }
];
}

const initialValue = [
  {
      children: [{ text: '' }],
  }
];

/*[
  {
    type: 'paragraph',
    children: [
      { text: 'This is editable ' },
      { text: 'rich', bold: true },
      { text: ' text, ' },
      { text: 'much', bold: true, italic: true },
      { text: ' better than a ' },
      { text: '<textarea>', 'code-line': true },
      { text: '!' },
    ],
  },
  {
    type: 'heading-one',
    children: [{ text: 'This is h1 ' }],
  },
  {
    type: 'heading-three',
    children: [{ text: 'This is h3 ' }],
  },
  {
    type: 'paragraph',
    children: [
      {
        text: `Since it's rich text, you can do things like turn a selection of text `,
      },
      { text: 'bold', bold: true },
      {
        text: ', or add a semantically rendered block quote in the middle of the page, like this:',
      },
    ],
  },
  {
    type: 'block-quote',
    children: [{ text: 'A wise quote.' }],
  },
  {
    type: 'paragraph',
    children: [{ text: 'Try it out for yourself!' }],
  },
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];*/


const SLATE_DEV_MODE_KEY = 'slate-dev';

const HOTKEYS = {
    'mod+b': MarkTypes.bold,
    'mod+i': MarkTypes.italic,
    'mod+u': MarkTypes.underline,
    'mod+`': MarkTypes.strike
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];