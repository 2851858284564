export interface IZipSearchResult {
    docs: IZip[]
    totalDocs: number
    limit: number
    totalPages: number
    page: number
    pagingCounter: number
    hasPrevPage: boolean
    hasNextPage: boolean
    prevPage: number | null
    nextPage: number | null
  }
  
  export interface IZip {
    id: string
    postalCode: string
    geoPoint: number[]
    name: string
  }
  