import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as qs from 'qs';
import { COMMENT_EMOJI_LIST, IReaction, IReview, IReviewSearchCriteria, IReviewSearchResult, REVIEW_EMOJI_LIST } from '../../models';
import { ReviewService } from '../../services';

@Component({
  selector: 'web-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
export class ReviewComponent implements OnInit, OnChanges {
  @Input() review: IReview;
  @Input() level: number = 0;

  isProviderReview: boolean = false;

  showReplies: boolean = false;
  replyLevel: number = 0;
  replies: IReviewSearchResult;
  emojiOptions: any = [];
  commentExpanded: boolean = false;

  constructor(
    private reviewService: ReviewService) { }
  
  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void  {
    if(changes['review']?.currentValue) {
      this.isProviderReview = this.review.replyPost.relationTo === 'providers';
      this.emojiOptions = this.isProviderReview ? REVIEW_EMOJI_LIST : COMMENT_EMOJI_LIST;
    }
  }

  loadReplies(page: number = 1, limit: number = 3) {

    if(!this.showReplies){
      const criteria = {
        page: page,
        limit: limit,
        query: {
          'replyPost.value': {
            equals: this.review.id,
          }
        }
      } as IReviewSearchCriteria;

      this.reviewService.find(criteria).subscribe(result => {
        this.replies = result;
        this.showReplies = true;
        this.replyLevel = this.level + 1;
        this.commentExpanded = true;
      });
    }
    else {
      this.showReplies = false;
    }
  }

  logReaction(symbol: string) {
    const reaction = {
      id: this.review.id,
      reaction: symbol
    } as IReaction;

    this.reviewService.logReaction(reaction).subscribe(x => {
      const existingReaction = this.review.reactionMeta.find(reaction => reaction.reaction === this.reviewService.convertToCodePoint(symbol))
    
      if(existingReaction) existingReaction.count++;
      else this.review.reactionMeta.push({reaction: this.reviewService.convertToCodePoint(symbol), count: 1, id: 'temp'});
    });
  }

  
}
