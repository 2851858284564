import { addDays, addHours, addMinutes, addMonths, addWeeks, addYears, differenceInCalendarDays, differenceInCalendarMonths, differenceInCalendarWeeks, differenceInCalendarYears, differenceInHours, differenceInMinutes, endOfDay, endOfHour, endOfMinute, endOfMonth, endOfWeek, endOfYear, startOfDay, startOfHour, startOfMinute, startOfMonth, startOfWeek, startOfYear, subDays, subHours, subMinutes, subMonths, subWeeks, subYears } from "date-fns";

export interface WebAnalyticDataPoint {
    x: string;
    y: number;
}

export interface WebAnalyticPageViews {
    pageviews: WebAnalyticDataPoint[];
    sessions: WebAnalyticDataPoint[];
}

export interface WebAnalyticStat {
    value: number;
    change: number;
}

export interface WebAnalyticStats {
    pageviews: WebAnalyticStat;
    visitors: WebAnalyticStat;
    visits: WebAnalyticStat;
    bounces: WebAnalyticStat;
    totaltime: WebAnalyticStat;
}

export interface WebAnalyticMetrics {
    event?: WebAnalyticDataPoint[],
    country?: WebAnalyticDataPoint[],
    device?: WebAnalyticDataPoint[],
    os?: WebAnalyticDataPoint[],
    browser?: WebAnalyticDataPoint[],
    referrer?: WebAnalyticDataPoint[],
    url?: WebAnalyticDataPoint[],
}

export interface WebAnalyticEventData {
    x: string;
    t: string;
    y: number;
}

export interface WebAnalyticDateRange {
    startUnixTime: number;
    endUnixTime: number;
    value: string;
}

export const ANALYTICS_DATE_OPTIONS = [
    { label: 'Today', value: '0day' },
    {
      label: 'Last 24 Hours',
      value: '24hour',
      dateFn: 'hour'
    },
    {
      label: 'This week',
      value: '0week',
      divider: true,
      dateFn: 'day'
    },
    {
      label: 'Last 7 days',
      value: '7day',
      dateFn: 'day'
    },
    {
      label: 'This month',
      value: '0month',
      divider: true,
      dateFn: 'day'
    },
    {
      label: 'Last 30 days',
      value: '30day',
      dateFn: 'day',
      selected: true,
    },
    {
      label: 'Last 90 days',
      value: '90day',
      dateFn: 'day'
    },
    { 
      label: 'This year', 
      value: '0year', 
      divider: true,
      dateFn: 'month'
    },
    {
      label: 'Last 6 months',
      value: '6month',
      dateFn: 'month'
    },
    {
      label: 'Last 12 months',
      value: '12month',
      dateFn: 'month'
    }
  ];


export const DATE_FUNCTIONS = {
    minute: {
      diff: differenceInMinutes,
      add: addMinutes,
      sub: subMinutes,
      start: startOfMinute,
      end: endOfMinute,
    },
    hour: {
      diff: differenceInHours,
      add: addHours,
      sub: subHours,
      start: startOfHour,
      end: endOfHour,
    },
    day: {
      diff: differenceInCalendarDays,
      add: addDays,
      sub: subDays,
      start: startOfDay,
      end: endOfDay,
    },
    week: {
      diff: differenceInCalendarWeeks,
      add: addWeeks,
      sub: subWeeks,
      start: startOfWeek,
      end: endOfWeek,
    },
    month: {
      diff: differenceInCalendarMonths,
      add: addMonths,
      sub: subMonths,
      start: startOfMonth,
      end: endOfMonth,
    },
    year: {
      diff: differenceInCalendarYears,
      add: addYears,
      sub: subYears,
      start: startOfYear,
      end: endOfYear,
    },
  };