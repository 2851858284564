<!--page-title-two-->
<section class="page-title-two">
    <div class="title-box centred bg-color-2">
        <div class="pattern-layer">
            <div class="pattern-1" style="background-image: url(assets/images/shape/shape-70.png);"></div>
            <div class="pattern-2" style="background-image: url(assets/images/shape/shape-71.png);"></div>
        </div>
        <div class="auto-container">
            <div class="title">
                <h1>Forbidden</h1>
            </div>
        </div>
    </div>
</section>
<!--page-title-two end-->
<!-- error-section -->
<section class="error-section centred">
    <div class="auto-container">
        <div class="inner-box">
            <h1>403</h1>
            <h2>You don't have permission to access this page.<br>
                Please contact the site administrator if you believe this is an error.</h2>
            <a href="index.html" class="theme-btn-one"><i class="fas fa-arrow-circle-left"></i>Go Back Home</a>
        </div>
    </div>
</section>
<!-- error-section end -->