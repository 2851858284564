<div *ngIf="!isReadOnly && enableToolbar" class="slate-toolbar">
    <web-slate-editor-toolbar-item
      *ngFor="let toolbarItem of toolbarItems"
      [active]="toolbarItem.active(toolbarItem.format)"
      (onMouseDown)="toolbarItem.action(toolbarItem.format)"
      ><span class="material-icons">{{ toolbarItem.icon }}</span></web-slate-editor-toolbar-item>
</div>
<div [ngClass]="{'edit-container': !isReadOnly}">
    <slate-editable 
    [editor]="editor" 
    [(ngModel)]="value" 
    (ngModelChange)="valueChange($event)"
    [renderElement]="renderElement" 
    [keydown]="keydown"
    [readonly]="isReadOnly"
    [renderText]="renderText"
    [spellCheck]="true">
    <ng-template #heading_1 let-context="context" let-viewContext="viewContext">
        <h1 slateElement [context]="context" [viewContext]="viewContext"></h1>
    </ng-template>
    <ng-template #heading_2 let-context="context" let-viewContext="viewContext">
        <h2 slateElement [context]="context" [viewContext]="viewContext"></h2>
    </ng-template>
    <ng-template #heading_3 let-context="context" let-viewContext="viewContext">
        <h3 slateElement [context]="context" [viewContext]="viewContext"></h3>
    </ng-template>
    <ng-template #blockquote let-context="context" let-viewContext="viewContext">
        <blockquote slateElement [context]="context" [viewContext]="viewContext"></blockquote>
    </ng-template>
    <ng-template #ul let-context="context" let-viewContext="viewContext">
        <ul slateElement [context]="context" [viewContext]="viewContext"></ul>
    </ng-template>
    <ng-template #ol let-context="context" let-viewContext="viewContext">
        <ol slateElement [context]="context" [viewContext]="viewContext"></ol>
    </ng-template>
    <ng-template #li let-context="context" let-viewContext="viewContext">
        <li slateElement [context]="context" [viewContext]="viewContext"></li>
    </ng-template>
</slate-editable>
</div>
