import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { IUser } from '@sw-cms/shared-types';

@Component({
  selector: 'web-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() user: IUser | null | undefined = null;
  @Input() isLoggedIn: boolean = false;
  
  companyName: string = environment.company.name;
  currentYear: number = (new Date()).getFullYear();

  constructor() { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
  }

}
