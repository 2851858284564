<div class="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
    <ul class="navigation clearfix">

        <li>
            <a [href]="isLoggedIn ? '/care-community' : '/#care-community'" class="nav-link">Care Community</a>
            <!--<ul>
                <li class="dropdown"><a href="patient-dashboard.html">Patient Dashboard</a>
                    <ul>
                        <li><a href="patient-dashboard.html">Dashboard</a></li>
                        <li><a href="favourite-doctors.html">Favourite Doctors</a></li>
                        <li><a href="schedule-timing-2.html">Schedule Timing</a></li>
                        <li><a href="message-2.html">Messages</a></li>
                        <li><a href="patient-profile.html">My Profile</a></li>
                        <li><a href="change-password-2.html">Change Password</a></li>
                        <li><a href="login-2.html">Logout</a></li>
                    </ul>
                </li>
                <li><a href="submit.html">Submit Review</a></li>
                <li><a href="book-appointment.html">Book Appointment</a></li>
                <li><a href="register-page.html">Register Page</a></li>
            </ul>-->
        </li>
        <li class="dropdown">
            <a routerLink="/about-us">About us</a>
            <ul [id]="'menuItemAboutUsList' + rnd">
                <li><a routerLink="." fragment="about-us" class="nav-link">Social Healthcare Platform</a></li>
                <li><a routerLink="/about-us">Company Overview</a></li>
                <li><a href="#">Careers</a></li>
                <li><a href="#">Contact Us</a></li>
            </ul>
            <div class="dropdown-btn" (click)="toggleMobileMemuItem($event, 'menuItemAboutUsList' + rnd)"><span class="fas fa-angle-down"></span></div>
        </li>
        <li class="dropdown">
            <a href="index.html">Resources</a>
            <ul [id]="'menuItemResourceList' + rnd">
                <li class="nav-item"><a routerLink="/" fragment="faq"  class="nav-link" routerLinkActive="active">Frequently Asked Questions</a></li>
            </ul>
            <div class="dropdown-btn" (click)="toggleMobileMemuItem($event, 'menuItemResourceList' + rnd)"><span class="fas fa-angle-down"></span></div>
        </li> 
        <li><a routerLink="/provider-service">Providers</a></li>
        
    </ul>
</div>