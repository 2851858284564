<div class="list">
    <web-review *ngFor="let doc of reviewsSearchResult?.docs" [review]="doc"></web-review>
</div>

<div *ngIf="isProvider && !reviewsSearchResult?.docs" class="empty-state">
    <h4>Be the first to share...</h4>
    <p>Help others by sharing your experience with {{target?.title}}</p>
    <a routerLink="/single-listings" class="default-btn">Write A Review</a>
</div>

