import { Injectable } from '@angular/core';
import { OperatorFunction, Observable, debounceTime, distinctUntilChanged, switchMap, map, catchError, of } from 'rxjs';
import { GeoService, ProviderService } from '.';
import { IProviderTokenSuggestionResult } from '../models';

@Injectable({
  providedIn: 'root'
})
export class TypeaheadService {

  constructor(private geoService: GeoService, private providerService: ProviderService) { }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchLocation: OperatorFunction<string, any> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      //tap(() => (this.searching = true)),
      switchMap((term) =>
        this.geoService.searchLocations(term).pipe(
          //tap(() => (this.searchFailed = false)),
          map(result => result.docs.map(item => item.name)),
          catchError(() => {
            //this.searchFailed = true;
            return of([]);
          }),
        ),
      ),
      //tap(() => (this.searching = false)),
    );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tokenSuggestion = (term: string) : Observable<IProviderTokenSuggestionResult> => {

        return this.providerService.tokenSuggestions({ providerToken: term, locationToken: "33065"})
  };
}
