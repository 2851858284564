export interface IPerspectiveResponse {
    attributeScores: IAttributeScores
    languages: string[]
    detectedLanguages: string[]
    min: number
    max: number
  }
  
  export interface IAttributeScores {
    TOXICITY: IDetail
    INSULT: IDetail
    THREAT: IDetail
    IDENTITY_ATTACK: IDetail
    PROFANITY: IDetail
  }
  
  export interface IDetail {
    spanScores: ISpanScore[]
    summaryScore: ISummaryScore
  }
  
  export interface ISpanScore {
    begin: number
    end: number
    score: IScore
  }
  
  export interface IScore {
    value: number
    type: string
  }
  
  export interface ISummaryScore {
    value: number
    type: string
  }
  
  