import { IBaseSearchCriteria } from "./base-search-criteria.model";


export interface IFaqSearchCriteria extends IBaseSearchCriteria {

}
  
export interface IFaqSearchResult {
    docs: IFaq[]
    totalDocs: number
    limit: number
    totalPages: number
    page: number
    pagingCounter: number
    hasPrevPage: boolean
    hasNextPage: boolean
    prevPage: number
    nextPage: number
  }
  
  export interface IFaq {
    id: string
    question: string
    answer: string
    sortOrder: number
    createdAt: string
    updatedAt: string
  }