<div *ngIf="formGroup" [formGroup]="formGroup">
    <ngb-rating [formControlName]="formControlName" [rate]="rating" [readonly]="readonly" [max]="max"
        [ngClass]="'rating-' + size" [ariaValueText]="ariaValueText">
        <ng-template let-fill="fill" let-index="index">
            <i class="bi-star-fill">
                <i [ngClass]="'rating-' + size" class="bi-star-fill filled" [style.width.%]="fill"></i>
            </i>
        </ng-template>
    </ngb-rating><span class="label" *ngIf="rating && showLabel">({{rating}})</span>
</div>
<div *ngIf="!formGroup">
    <ngb-rating [rate]="rating" [readonly]="readonly" [max]="max"
        [ngClass]="'rating-' + size" [ariaValueText]="ariaValueText">
        <ng-template let-fill="fill" let-index="index">
            <i class="bi-star-fill">
                <i [ngClass]="'rating-' + size" class="bi-star-fill filled" [style.width.%]="fill"></i>
            </i>
        </ng-template>
    </ngb-rating><span class="label" *ngIf="rating && showLabel">({{rating}})</span>
</div>