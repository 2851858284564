import { Component, Input, forwardRef } from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbRating } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'web-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StarRatingComponent),
      multi: true
    }
  ]
})
export class StarRatingComponent extends NgbRating {
  @Input() rating: number = 0;
  //@Input() max: number = 5;
  //@Input() readonly: boolean = true;
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() formControlName: string;
  @Input() formGroup?: FormGroup;
  @Input() showLabel?: true;

  override ariaValueText(current: number, max: number) {
    return `${current} out of ${max} stars`;
  }
}
