<div class="single-review-box">
    <figure class="image-box"><img src="assets/images/resource/review-1.jpg" alt=""></figure>
    <web-star-rating [rating]="review?.surveyMeta.compositeRating" [max]="5" size="sm" [readonly]="true" [showLabel]="true"></web-star-rating>
    <div *ngIf="isProviderReview" class="col-md-9 col-9 col-sm-9 p-0">
        <h4>{{review.title}}</h4>
    </div>
    <web-slate-editor [content]="review.content" [isReadOnly]="true"></web-slate-editor>
    <h6>{{review?.createdBy?.username}} <span>- {{review.createdAt | date: 'longDate'}}</span></h6>
</div>

<!--
<div class="user-review">
    <div class="row m-0">
        <div class="col-lg-12 col-md-12 p-0">
            <div class="main">
                <div class="row m-0">
                    <div *ngIf="isProviderReview" class="col-md-9 col-9 col-sm-9 p-0">
                        <h4>{{review.title}}</h4>
                    </div>
                    <div *ngIf="isProviderReview" class="col-md-3 col-3 col-sm-3 p-0 text-end">
                        <i class="bx bx-like"></i> Helpful (same as like reaction?)
                    </div>
                </div>

                <div *ngIf="isProviderReview">
                    <web-star-rating [rating]="review?.surveyMeta.compositeRating" [max]="5"
                        size="sm"></web-star-rating>
                </div>

                <web-slate-editor [content]="review.content" [isReadOnly]="true"></web-slate-editor>

                <div class="row m-0">
                    <div class="col-lg-4 col-md-4 col-4 col-sm-4 p-0">
                        <ul class="action-left">
                            <li>
                                <div ngbDropdown class="d-inline-block">
                                    <a ngbDropdownToggle id="reactionToggle"><i class='bx bx-plus-circle'></i> React</a>
                                    <div ngbDropdownMenu aria-labelledby="reactionToggle" class="reactionOptions">
                                        <button ngbDropdownItem *ngFor="let option of emojiOptions"
                                            (click)="logReaction(option.symbol)"><span
                                                class="symbol">{{option.symbol}}</span> <span class="label">
                                                {{option.label}}</span> </button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <span *ngFor="let reaction of review?.reactionMeta" class="badge bg-light text-dark">
                            {{reviewService.convertFromCodePoint(reaction?.reaction)}}  {{reaction.count}}
                        </span>
                    </div>
                    <div class="col-lg-4 col-md-4 col-4 col-sm-4 p-0 text-center text-nowrap">
                        <ul class="action-right">
                            <li>
                                <a routerLink="/single-listings">
                                    <i class="bx bx-photo-album"></i> Images
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-4 col-md-4 col-4 col-sm-4 p-0 text-end text-nowrap">
                        <ul class="action-right">
                            <li>
                                <a (click)="loadReplies()">
                                    <span
                                        *ngIf="review?.aggMeta?.totalReplies && review?.aggMeta?.totalReplies > 0; else default_comment"><i
                                            class='bx bx-comment'></i> Comments ({{review.aggMeta.totalReplies}}) <i
                                            class="bx bx-chevron-down"></i></span>
                                    <ng-template #default_comment><i class='bx bx-comment'></i> Comment</ng-template>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row m-0 mt-5">
        <div class="col-lg-6 col-md-6 col-sm-6 p-0">
            <div class="user">
                <div class="d-flex">
                    <img src="assets/img/user1.jpg" alt="image">
                    <div class="title">
                        <h4>KitKat79</h4>
                        <span>{{review.createdAt | date: 'short'}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 p-0 text-end">

        </div>
    </div>
    <div *ngIf="showReplies" class="row">
        <div class="col-12">
            <div class="reply-list mt-5">
                <h3 class="header">Replies</h3>
                <web-review *ngFor="let doc of replies?.docs" [review]="doc" [level]="replyLevel"></web-review>
            </div>

        </div>
    </div>
</div>
-->