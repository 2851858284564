import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { join } from 'lodash';
import { environment } from '../../../environments/environment';
import { IReaction, IReview, IReviewSearchCriteria, IReviewSearchResult } from '../models/review.model';
import { Observable, catchError, map, throwError } from 'rxjs';
import * as qs from 'qs';

@Injectable({
  providedIn: 'root'
})
export class ReviewService {

  private collection: string = 'reviews';
  private baseUrl: string = join([environment.api.url, 'api', this.collection], '/');

  constructor(private http: HttpClient) { }

  find = (criteria: IReviewSearchCriteria): Observable<IReviewSearchResult> => {
    let url = `${this.baseUrl}?`;

    if (criteria.draft) {
      url += `draft=${criteria.draft}&`;
    }

    if (criteria.depth) {
      url += `depth=${criteria.depth}&`;
    }

    if (criteria.sort) {
      url += `sortBy=${criteria.sort}&`;
    }

    if (criteria.page) {
      url += `page=${criteria.page}&`;
    }

    if (criteria.limit) {
      url += `limit=${criteria.limit}&`;
    }

    if (criteria.query) {
      const stringifiedQuery = qs.stringify(
        {
          where: criteria.query, // ensure that `qs` adds the `where` property, too!
        },
        { addQueryPrefix: false },
      )

      url += `${stringifiedQuery}&`;
    }

    return this.http
      .get<any>(url, {})
      .pipe(
          map(res => res.payload),
          catchError((err: HttpErrorResponse) => throwError(err)));
  };

  logReaction = (reaction: IReaction) => {
    const url = `${this.baseUrl}/${reaction.id}/reaction?`;

    if(reaction.reaction)
      reaction.reaction = reaction.reaction.codePointAt(0)?.toString(16) || reaction.reaction;

    return this.http
      .post<any>(url, reaction)
      /*.pipe(
          map(res => res.payload),
          catchError((err: HttpErrorResponse) => throwError(err)))*/;
  }

  save = (review: IReview) => {
    const url = `${this.baseUrl}?`;

    if(review?.content[0]?.children?.text) {
      review.content = review.content.map(item => {
        return {
            type: item.type || 'paragraph',
            children: [
              {
                ...item.children
              }
            ]
        };
      });
    }

    return this.http
      .post<any>(url, review)
      .pipe(
          map(res => res.payload),
          catchError((err: HttpErrorResponse) => throwError(err)));
  }

  convertFromCodePoint = (point: any) => {
    // @ts-ignore
    return String.fromCodePoint("0x" + point) || point;
  }

  convertToCodePoint = (symbol: string) => {
    return symbol.codePointAt(0)?.toString(16) || symbol
  }
}
