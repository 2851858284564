import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'web-tri-state-toggle',
  templateUrl: './tri-state-toggle.component.html',
  styleUrls: ['./tri-state-toggle.component.scss'],
})
export class TriStateToggleComponent {

  @Input() type: string = 'N_NA_Y';
  @Input() id: string;
  @Output() selectionChanged = new EventEmitter<Record<string, string>>();

  currentValue: string | null = null;

  options: {
    N_NA_Y: [
      { value: 'N', icon: 'x', defaultValue: false },
      { value: 'NA', icon: '', defaultValue: true },
      { value: 'Y', icon: 'check', defaultValue: false },
    ]
  }

  onSelection(value) {
    this.selectionChanged.emit({ id: this.id, value })
  }
}
