
export interface IInternalStripeVerificatonSession {
    _id: any
    stripe_id: string
    client_reference_id: string
    created: number
    last_error: any
    livemode: boolean
    provided_details: any
    raw_data: {
        id: string
        object: string
        livemode: boolean
        status: string
        client_reference_id: any
        client_secret: string
        created: number
        last_error: any
        last_verification_report: any
        metadata: {
            client_reference_id: string
            npi: string
            provider_id: string
            provider_specialty: string
            provider_title: string
            state_license_number: string
        }
        options: any
        redaction: any
        related_customer: any
        type: string
        url: string
        verification_flow: string
      }
    status: string
    url: string
    verification_flow: any
    verified_email: any
    verified_outputs: any
}
