import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { join } from 'lodash';
import { catchError, map, Observable, throwError } from 'rxjs';
import { IInternalStripeVerificatonSession } from '../models';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StripeProxyService {
  private baseUrl: string = join([environment.api.url, 'api', 'webhook'], '/');

  constructor(private http: HttpClient) { 
  }

  getVerificationSession = (verificationSessionId: string): Observable<IInternalStripeVerificatonSession> => {

    const url = `${this.baseUrl}/stripe/identity/verification/session/${verificationSessionId}`;

    return this.http
      .get<any>(url)
      .pipe(
          map(res => res.payload),
          catchError((err: HttpErrorResponse) => throwError(err)));
  };
}

