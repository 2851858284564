import { Component, Input } from '@angular/core';
import { IUser } from '@sw-cms/shared-types';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'web-header-avatar',
  templateUrl: './header-avatar.component.html',
  styleUrls: ['./header-avatar.component.scss'],
})
export class HeaderAvatarComponent {
  @Input() user: IUser | null | undefined = null;
  @Input() isLoggedIn: boolean = false;

  constructor() { }
    
    forum = {
        link: environment.discourse.url,
        label: environment.discourse.label,
        icon: environment.discourse.icon
    }
}
