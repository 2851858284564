import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  LTOKEN_KEY = 'ltoken';
  CLAIM_NPI = 'claim.npi';
  CLAIM_LN = 'claim.ln'
  CLAIM_PROV = 'claim.prov'
  CLAIM_VSID = 'claim.vsid'
  CLAIM_EMAIL = 'claim.email'

  constructor() { }

  public saveData(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  public getData(key: string) {
    return localStorage.getItem(key)
  }
  public removeData(key: string) {
    localStorage.removeItem(key);
  }

  public clearData() {
    localStorage.clear();
  }
}
